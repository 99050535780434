import React, { useEffect, useMemo } from 'react';
// import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { addTab, getTabs, closeTab, changeReticlesID, selectActiveTab, setUpdateData, setNewName, setTabsAfterReload, setTabsIDAfterReload, setActiveTabAfterReload, addSavingID, setShareStatus } from './redux/reticles/tabs-reducer';
import { addZoomData, deleteZoomData, setCurrentZoomData, updateAfterRefrefh } from './redux/zoom/zoom-reducer';
import { addGridData, deleteGridData, refreshGridData } from './redux/grid/grid-reducer';
import { addDeviceData, deleteDeviceData } from './redux/devices/device-reducer';
import { addColorThemeData, deleteColorThemeData, setCurrentThemeData, updateThemesAfterRefrefh } from './redux/colorTheme/colorTheme-reducer';
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import _debounce from 'lodash.debounce';

import 'normalize.css';
import './assets/styles/fonts-style.css'
import './index.css';

import Editor from './Editor';
import Preview from './assets/routes/preview';

import { getCurrentUser } from './redux/auth/auth-operations';
import { changeCurrentReticle, exportSuccess, exportError, openReticle, closeReticle, clearDownloadReticle } from './redux/reticles/reticles-actions';
import { getIsSavingId, getCurrentReticleStatus, getCurrentReticlePublicId, openReticleInfo, getDownloadReticle } from './redux/reticles/reticles-selectors';
import { getIsAuthenticated } from './redux/auth/auth-selectors';
import { addReticles, resaveReticles, fetchShareReticle, fetchDownloadReticle } from './redux/reticles/reticles-operations';
import clearSvgData from '../../reticle-editor/src/assets/components/utils/clearSvgFromFillOpacity';

import { useState } from "react";

var isFirstSave = true;

export default function App() {
    const dispatch = useDispatch();
    const tabs = useSelector((state) => state.tabs.tabs);
    const retID = useSelector((state) => state.tabs.reticlesID);
    const atcTab = useSelector((state) => state.tabs.activeTabId);
    const openReticleInformation = useSelector(openReticleInfo);

    const [appVersion, setAppVersion] = useState('5.44.358');
    const [name, setName] = useState('My Reticle');
    const [reticles, setReticles] = useState([{ id: `tab-0`, name: 'My Reticle', savingId: '', svg: '', isActiveTab: true, status: '', publicId: '' },]);
    const [reticlesID, setReticlesID] = useState([{id: `tab-0`, class: 'canvas'},])
    const [activeTabId, setActiveTabId] = useState('tab-0');
    const [svgDataObj, setSvgDataObj] = useState(...tabs);
    const [randomID, setRandomID] = useState((Math.random() * 100000).toFixed(0));
    const [currentURL, setCurrentURL] = useState(useLocation());
    const currentReticleId = useSelector(getIsSavingId);
    const currentPublicId = useSelector(getCurrentReticlePublicId);

    const openedTabs = useSelector(getTabs);

    const loginStatus = useSelector(getIsAuthenticated);
    const currentStatus = useSelector(getCurrentReticleStatus);
    const devices = useSelector((state) => state.device.deviceData);

    const downloadReticle = useSelector(getDownloadReticle);

    const [focused, setFocused] = useState(false);
    const [currentShapesID, setCurrentShapesID] = useState([]);
    const [cleanSvg, setCleanSvg] = useState('');
    const [changeId, setChangeId] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [firstSave, setFirstSave] = useState(false);
    const [activeLink, setActiveLink] = useState('1');
    const [deviceWidth, setWidth] = useState(window.innerWidth);

    const [resetSvgValue, setResetSvgValue] = useState('');
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    // const mobileDevice = useMediaQuery("only screen and (max-width : 767px)");
    // const tabletDevice = useMediaQuery("only screen and (min-width : 768px) and (max-width : 1019px)");
    // const isLargeDevice = useMediaQuery("only screen and (min-width : 993px) and (max-width : 1200px)");
    // const desktopDevice = useMediaQuery("only screen and (min-width : 1020px)");
    const [shapesList, setShapesList] = useState([]);

    const [timerId, setTimerId] = useState([]);

    const [activeButton, setActiveButton] = useState('1');
    const [activeLib, setActiveLib] = useState('1');
    const [burgerButtonName, setBurgerButtonName] = useState('My reticles');


    const [openCard, setOpenCard] = useState(false);
    const [isReticleCardOpen, setIsReticleCardOpen] = useState(false);
    const [reticle, setReticle] = useState(null);

    const [openShareReticle, setOpenShareReticle] = useState(false);

    const defaultTagSVG = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1"></svg>`;

    const handleActiveLib = (value) => {
        setActiveButton(value);
        setActiveLib(value);
        setBurgerButtonName(value === '1' ? 'My reticles' : 'Public reticles');
    };

    const handleActiveLibWithChangeFolder = (name) => {
        setActiveButton('');
        setActiveLib('2');
        setBurgerButtonName(name);
    };

    const handleClearActiveLib = () => {
        setActiveButton('1');
        setActiveLib('1');
        setBurgerButtonName('My reticles');
        setIsReticleCardOpen(false);
        setOpenCard(false);
        setReticle(null);
    };


    const handleOpenCard = (reticle) => {
        setOpenCard(true);
        handleReticleCardOpen(true);
        setReticle(reticle);
        dispatch(openReticle(reticle));
    };

    const handleReticleCardOpen = (value) => {
        setIsReticleCardOpen(value)
    };

    const closeOpenCard = () => {
        setOpenCard(false);
    };

    const handleCloseCard = () => {
        setOpenCard(false);
        handleReticleCardOpen(false);
        dispatch(closeReticle());
    };

    useEffect(() => {
        const handleResize = _debounce(() => setWidth(window.innerWidth), 0)

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
            if (sessionStorage.getItem('activeLink')) {
                let num = JSON.parse(sessionStorage.getItem('activeLink'));

                setActiveLink(num.activeLink );
        }   else {
                if (deviceWidth < 1020 ) {
                    setActiveLink('2');
                } else if (deviceWidth >= 1020) {
                    setActiveLink('1');}
            }

            // else {
            //     if (mobileDevice || tabletDevice ) {
            //         // console.log('mob', mobileDevice )
            //         // console.log('tablet', tabletDevice)

            //         setActiveLink('2');
            //     } else if (desktopDevice) {
            //         // console.log('desk', desktopDevice)

            //         setActiveLink('1');}
            // }
    }, []);

   const handlerActivateButton = (num) => {
        setActiveLink(num);

        let historyData = JSON.stringify({ activeLink: num });
        sessionStorage.setItem(`activeLink`, historyData);
    };

    useEffect(() => {
        dispatch(getCurrentUser());
    }, [dispatch]);

    useEffect(() => {
        setActiveTabId(atcTab);

        if (currentReticleId) {
            isFirstSave = false;
        } else {
            isFirstSave = true;
        }
    }, [atcTab]);

    const handlerReticleRename = (reticleName) => {
        setName(reticleName);
        setTabNewName({id: activeTabId, name: reticleName});
        setReticles(reticles.map(obj => {
            if (obj.id === activeTabId) {
                return { ...obj, name: reticleName };
            } else {
                return obj;
            }
        }));
    }

    const handlerActiveTab = (id, name) => {
        if (activeTabId !== id) {
            setActiveTabId(id);
            setName(name);
        }
        
        dispatch(changeReticlesID({id: id, action: 'SELECT'}));
        dispatch(selectActiveTab({id: id, action: 'SELECT'}));
        dispatch(setCurrentZoomData(id));
        dispatch(setCurrentThemeData(id));
        
        const currentTab = tabs.filter(i => i.id === id)[0];
        dispatch(changeCurrentReticle(currentTab));

        let historyData = JSON.stringify( id );
        sessionStorage.setItem(`activeTab`, historyData);
    }

    const handlerAddTab = (e, importReticle, importReticleName) => {
        if (tabs && tabs.length < 10) {
            setRandomID((Math.random() * 100000).toFixed(0));

            const retObj = {
                id: randomID,
                name: importReticleName ? importReticleName : 'My Reticle',
                svg: importReticle ? importReticle : '',
                savingId: '',
                isActiveTab: true,
                status: 'PRIVATE',
                publicId: '',
            }

            let jsonData = JSON.stringify({ id: `tab-${randomID}`, savingId: '', svg: importReticle ? importReticle : defaultTagSVG, name: importReticleName ? importReticleName : 'My Reticle', isActiveTab: true, status: 'PRIVATE', publicId: '', });
            sessionStorage.setItem(`tab-${randomID}`, jsonData);

            let historyData = JSON.stringify(`tab-${randomID}`);
            sessionStorage.setItem(`activeTab`, historyData);

            dispatch(addTab(retObj));
            dispatch(changeReticlesID({ id: randomID, action: 'ADD' }));
            dispatch(selectActiveTab({ id: randomID, action: 'ADD' }));
            dispatch(addZoomData({ id: `tab-${randomID}`, index: 100 }));
            dispatch(addColorThemeData({ id: `tab-${randomID}`, isNightMode: false, colorTheme: '#fff' }));
            dispatch(addGridData({ id: `tab-${randomID}`, gridType: 'moa', gridSize: 3 }));
            dispatch(addDeviceData({ id: `tab-${randomID}`, device: 'X-Sight 5', sensor: '320', lens: '3-15X', width: 1280, height: 960 }));
        }
    }

    const handlerUpdateTabData = (updatedData) => {
        dispatch(setUpdateData(updatedData));
    }

    const setTabNewName = (newName) => {
        dispatch(setNewName(newName))
    }

    const handlerCloseTab = (e, id) => {
        e.stopPropagation();

        dispatch(closeTab(id));
        dispatch(changeReticlesID({id: id, action: 'REMOVE'}));
        dispatch(selectActiveTab({id: id, action: 'REMOVE'}));

        sessionStorage.removeItem(id);
        const lastTab = tabs.filter(el => el.id !== id);

        let historyData = JSON.stringify(lastTab[lastTab.length - 1].id);
        sessionStorage.setItem(`activeTab`, historyData);

        dispatch(changeCurrentReticle(lastTab[lastTab.length - 1]));
        dispatch(deleteZoomData(id));
        dispatch(deleteGridData(id));
        dispatch(deleteDeviceData(id));
        dispatch(deleteColorThemeData(id));
    }

    // useEffect(() => {
    //     const last = reticles[reticles.length - 1];
    //     setActiveTabId(last.id)
    // }, [reticles.length]);

    const activeTab = useMemo(() => (
        reticles.find((tab) => (
            tab.id === activeTabId
        ))
    ), [activeTabId, reticles]);

    useEffect(() => {
        if (openReticleInformation.id && openShareReticle) {
            // console.log('openReticleInformation', openReticleInformation.id)
            setOpenCard(true);
            handleReticleCardOpen(true);
            setReticle(openReticleInformation);

            setOpenShareReticle(false)
        }

    }, [openReticleInformation]);

    useEffect (() => {
        const currentID = currentURL.search.replace('?id=', '');

        if (currentID) {
            setActiveLink('2');
            setActiveLib('2');
            setActiveButton('2');
            setBurgerButtonName('Public reticles');

        //    axios.get(`/reticle/shared/${currentID}`)
        //         .then(res => {
        //             handleOpenCard(res.data)
        //             // sessionStorage.setItem('tab-0', JSON.stringify(res.data.reticleImg))
        //             // dispatch(setUpdateData({id: 'tab-0', svg: res.data.reticleImg}))
        //             // dispatch(setNewName({id: 'tab-0', name: res.data.name}))
        //             // dispatch(setShareStatus({id: 'tab-0', status: true}))
        //             // setReticles({ id: `tab-0`, name: res.data.name, savingId: res.data.id, svg: res.data.reticleImg, isActiveTab: true, status: res.data.status })  
        //         }).catch(err => console.log('[ERROR] ', err))
            dispatch(fetchShareReticle(currentID));
            setOpenShareReticle(true);
        }
        const tabsInStorage = Object.keys(sessionStorage);

        if (tabsInStorage.length) {
            let activeTab = JSON.parse(sessionStorage.getItem('activeTab'));

            let activeTabId = !activeTab ? 'tab-0' : activeTab ;

            let tabsID = JSON.parse(sessionStorage.getItem('retID'));

            // const tabsArr = tabsInStorage.filter(el => el !== 'undoData' && el !== 'retID' && el !== 'activeTool' && !el.includes('svgCanvas') && el !== 'shapesList' && el !== 'activeLink' && el !== 'devices');
            const tabsArr = tabsID.map(el => el.id)

            let tabsObj = tabsArr.map(el => {
                let objData = JSON.parse(sessionStorage.getItem(el))

                return { 
                    id: el, 
                    name: objData.name, 
                    svg: objData.svg, 
                    savingId: objData.savingId, 
                    isActiveTab: false, 
                    publicId: objData.publicId,
                    status: objData.status,
                    reticleUserEmail: objData.reticleUserEmail
                }
            })

            let reloadTabsID = tabsID.map((tab) => {
                if (tabsObj.length && tab.id === activeTabId) {
                    return {id: tab.id, class: 'canvas', index: 100}
                } else {
                    return {id: tab.id, class: 'canvas canvas-disabled', index: 100}
                }
            })

            // let reloadGridData = tabsID.map((tab, index) => {
            //     if (tabsID.length - 1 !== index) {
            //         return {id: tab.id, gridType: 'moa', gridSize: 3}
            //     } else {
            //         return {id: tab.id, gridType: 'moa', gridSize: 3}
            //     }
            // })

            if (tabsObj.length && reloadTabsID.length) {

                let tabD = reloadTabsID.find(el => el.id === activeTabId);

                dispatch(setTabsAfterReload(tabsObj));
                dispatch(setTabsIDAfterReload(reloadTabsID));
                dispatch(updateAfterRefrefh(reloadTabsID));
                // dispatch(refreshGridData(reloadGridData));

                try {
                    if (tabD.id) {
                        dispatch(setActiveTabAfterReload(tabD.id));
                        dispatch(changeCurrentReticle(tabsObj.filter(el => el.id === activeTabId)[0]));
                        setActiveTabId(tabD.id);
                    }
                } catch(e) {
                    console.log(e);
                }

                setReticles(tabsObj);
                setSvgDataObj(tabsObj);
            }

            let themes = JSON.parse(sessionStorage.getItem('colorThemes'));

            if (themes) {
                dispatch(updateThemesAfterRefrefh([themes, activeTabId]));
            }
        } else {
            setSvgDataObj(tabs);
            setReticles(tabs);
        }
    }, []);

    useEffect(() => {
        setSvgDataObj(tabs);
        setReticles(tabs);
    }, [tabs]);

    useEffect(() => {
        if (!sessionStorage.getItem('Grids')) {
            sessionStorage.setItem('Grids', JSON.stringify([{id: 'tab-0', gridType: 'moa', gridSize: 3}]));
        } else {
            const reloadGridData = JSON.parse(sessionStorage.getItem('Grids'));

            dispatch(refreshGridData(reloadGridData));
        }
    }, [])

    useEffect(() => {
        setReticlesID(retID);

        sessionStorage.setItem('retID', JSON.stringify(retID));
    }, [retID]);

    useEffect(() => {
        const tabsInStorage = Object.keys(sessionStorage);

        let payload
        if (!tabsInStorage.includes(`tab-0`)) {
            payload = {
                id: `tab-0`,
                name: 'My Reticle',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" width="600" height="450" id="svgCanvas-tab-0" class="canvas-block" viewBox="0 0 600 450" xmlns:svgjs="http://svgjs.dev/svgjs"><ellipse rx="0" ry="0" cx="300" cy="225" id="aimpoint" class="aimPoint"></ellipse></svg>',
                savingId: '',
                isActiveTab: true,
                publicId: '',
                status: 'PRIVATE',
                shapeList: [],
                isLibOpen: false
            }
        } else {
            let objData = JSON.parse(sessionStorage.getItem(`tab-0`))

            payload = { ...objData }
        }

        let data = JSON.stringify(payload);
        sessionStorage.setItem(`tab-0`, data);
    }, []);


    const resetReticleViewBox = (svg) => {
        const regData = /viewBox="([^"]*)"/g;
        const regNewData = 'viewBox="0 0 600 450"';
        const resetSvg = svg.replace(regData, regNewData);

        return resetSvg;
    }

    const handlerExport = async (reticle) => {
        // try {
            let exportObj = reticle? ({ id: reticle.id}) : ({ id: currentReticleId !== '' ? currentReticleId : currentPublicId });
            // const response = await axios.post('/reticle/export', exportObj);

            dispatch(fetchDownloadReticle(exportObj));

            // let downloadData = resetReticleViewBox(response.data);
            // let link = document.createElement('a');
            // link.download = reticle ? `${reticle.name}.atn` : `${activeTab.name}.atn`;
            // let blob = new Blob([downloadData]);
            // link.href = URL.createObjectURL(blob);
            // link.click();
            // URL.revokeObjectURL(link.href);
            // dispatch(exportSuccess());
        // } catch (error) {
        //     dispatch(exportError(error.response.data.message[0]));
        // }
    };

    useEffect(() => {
        if (downloadReticle && firstRender) {
            let downloadData = resetReticleViewBox(downloadReticle);
            const clearedData = clearSvgData(downloadData);
            let link = document.createElement('a');
            link.download = reticle ? `${reticle.name}.atn` : `${activeTab.name}.atn`;
            let blob = new Blob([clearedData]);
            link.href = URL.createObjectURL(blob);
            link.click();
            URL.revokeObjectURL(link.href);

            dispatch(clearDownloadReticle())
        }

    }, [downloadReticle]);


    const onFocus = () => setFocused(true)

    const onBlur = (svg) => {
        setFocused(false);

        if (cleanSvg === '' && typeof svg === 'string') {
            handlerSvgUpdateData(svg)
        } else if (cleanSvg !== '') {
            handlerSvgUpdateData(cleanSvg)
        } else if (cleanSvg === '') return

        setCleanSvg('');
    }

    const clearAllTimeouts = (timeIDArr) => {
        if(timeIDArr.length) {
            timeIDArr.forEach(el => {
                clearTimeout(el);
            });

            timeIDArr = [];
        }
    };

    const saveReticle = (ret) => {
        dispatch(resaveReticles(ret));
    }

    const handlerSvgUpdateData = async (svgValue, shapesArr = ['default']) => {
        let resetReticleSvgValue = resetReticleViewBox(svgValue);

        if (resetReticleSvgValue.includes(currentReticleId) && resetReticleSvgValue.includes(activeTabId)) {
            setResetSvgValue(resetReticleViewBox(svgValue));

            if (shapesArr[0] !== 'default') {
                handlerUpdateTabData({ id: activeTab.id, svg: resetReticleSvgValue, shapeList: shapesArr });
    
                setShapesList(shapesArr);
            }

            setCleanSvg(resetReticleSvgValue);
            
            let jsonData = JSON.stringify({ id: activeTabId, savingId: currentReticleId, svg: resetReticleSvgValue, name: activeTab.name, isActiveTab: true, status: currentStatus, publicId: currentPublicId, });

            sessionStorage.setItem(activeTabId, jsonData);
        }

        if (currentReticleId !== '' && loginStatus) {
            const reticle = {
                id: currentReticleId,
                name: activeTab.name,
                reticleImg: resetReticleSvgValue,
                shapesList: currentShapesID
            }

            clearAllTimeouts(timerId);

            if (resetReticleSvgValue.includes(`${activeTabId}`)) {
                let timerIDs = setTimeout(saveReticle, 3000, reticle);
    
                timerId.push(timerIDs);
            }

            // dispatch(resaveReticles(reticle));
        } else if (loginStatus && currentReticleId === '') {
            if (resetReticleSvgValue.includes(`${activeTabId}`)) {
                if (isFirstSave) {
                    isFirstSave = false;

                    const reticle = {
                        name: activeTab.name,
                        reticleImg: resetReticleSvgValue,
                        shapesList: currentShapesID
                    }
    
                    setFirstSave(true);
                    dispatch(addReticles(reticle));
                    setChangeId(true);
                }
            }

        } else return
    }

    useEffect(() => {
        if (firstRender){
            var aValue = JSON.parse(sessionStorage.getItem(activeTab.id));
        }

        if (shapesList.length) {
            handlerUpdateTabData({ id: activeTab.id, svg: aValue?.svg, shapeList: shapesList })
        }

        // let jsonData = JSON.stringify({ id: activeTabId, savingId: currentReticleId, svg: svgValue, name: activeTab.name, isActiveTab: true, status: '' });
        // sessionStorage.setItem(activeTabId, jsonData);
    }, [loginStatus]);

    useEffect(() => {
        setFirstRender(true);
    }, []);

    // useEffect(() => {
    //     if (mobileDevice || tabletDevice) {
    //         setActiveLink('2');
    //     }
    // }, [mobileDevice, tabletDevice]);

    useEffect(() => {
        // console.log('width', deviceWidth)
        if (deviceWidth < 1020) {
            setActiveLink('2');
        }
    }, [deviceWidth]);


    useEffect(() => {
        if (currentReticleId && changeId) {
            dispatch(addSavingID({ id: activeTabId, savId: currentReticleId, status: currentStatus, publicId: '' }))
            setChangeId(false)

            let jsonData = JSON.stringify({ id: activeTabId, savingId: currentReticleId, svg: resetSvgValue, name: activeTab.name, isActiveTab: true, status: currentStatus, publicId: currentPublicId, });

            sessionStorage.setItem(activeTabId, jsonData);

            // setResetSvgValue('') 
        }
    }, [currentReticleId, dispatch]);

    useEffect(() => {
        if (!isFirstLoad) {
            sessionStorage.setItem('devices', JSON.stringify(devices));
        }

        setIsFirstLoad(false);
    }, [devices])

    const getCurrentID = (arr) => {
        if (currentShapesID !== arr) {
            setCurrentShapesID(arr)
        }
    }

    const setCleanedSvg = (svg) => setCleanSvg(svg)

    const disableFirstSave = () => {
        if (firstSave) {
            setFirstSave(false)
        }
    }

    // useEffect(() => {
    //     const currentID = currentURL.search.replace('?id=', '');

    //     if (currentID) {
    //         axios.get(`/reticle/shared/${currentID}`)
    //             .then(res => {
    //                 console.log('[SHARE] ', res.data)
    //                 sessionStorage.setItem('tab-0', res.data.reticleImg)
                    // dispatch(setUpdateData({id: 'tab-0', svg: res.data.reticleImg}))
                    // dispatch(setNewName({id: 'tab-0', name: res.data.name}))
                    // dispatch(addSavingID({id: 'tab-0', savId: res.data.id, status: res.data.status}))
                    // dispatch(setShareStatus({id: 'tab-0', status: true}))
                    // setReticles({ id: `tab-0`, name: res.data.name, savingId: res.data.id, svg: res.data.reticleImg, isActiveTab: true, status: res.data.status })
    //             })
    //             .catch(err => console.log('[ERROR] ', err))
    //     }
    // }, [])

    return (
        <Routes>
            <Route path="/" element={
            <Editor
                appVersion={appVersion}
                name={name}
                handlerReticleRename={handlerReticleRename}
                handlerAddTab={handlerAddTab}
                handlerCloseTab={handlerCloseTab}
                activeTab={activeTab}
                reticles={reticles}
                activeTabId={activeTabId}
                handlerActiveTab={handlerActiveTab}
                reticlesID={reticlesID}
                svgDataObj={svgDataObj}
                handlerUpdateTabData={handlerUpdateTabData}
                onExportingTool={handlerExport}
                focus={onFocus}
                blur={onBlur}
                focusedInput={focused}
                getCurrentID={getCurrentID}
                shapes={currentShapesID}
                svgUpdateData={handlerSvgUpdateData}
                setCleanedSvg={setCleanedSvg}
                firstSave={firstSave}
                disableFirstSave={disableFirstSave}
                handlerActivateButton={handlerActivateButton}
                activeLink={activeLink}
                // desktopDevice={desktopDevice}
                deviceWidth={deviceWidth}
                handleReticleCardOpen={handleReticleCardOpen}
                openCard={openCard}
                handleOpenCard={handleOpenCard}
                reticle={reticle}
                closeOpenCard={closeOpenCard}
                isReticleCardOpen={isReticleCardOpen}
                handleCloseCard={handleCloseCard}
                handleActiveLib={handleActiveLib}
                handleActiveLibWithChangeFolder={handleActiveLibWithChangeFolder}
                activeButton={activeButton}
                activeLib={activeLib}
                burgerButtonName={burgerButtonName}
                handleClearActiveLib={handleClearActiveLib}
            />}
            />
            <Route path="/preview" element={
                <Preview 
                    handlerReticleRename={handlerReticleRename}
                    handlerAddTab={handlerAddTab}
                    handlerCloseTab={handlerCloseTab}
                    activeTab={activeTab}
                    reticles={reticles}
                    activeTabId={activeTabId}
                    handlerActiveTab={handlerActiveTab}
                    onExportingTool={handlerExport}
                    focus={onFocus}
                    blur={onBlur}
                    isFocused={focused}
            />} />
            <Route path='/shared' element={<Navigate to='/' replace />} />
        </Routes>
    );
}
